<template>
  <modal :show-modal="modalIsVisible" @close-modal="dismissSuccessfully(null)" :contentClass='"w-50"'>
    <template v-slot:header>
      <div class='d-flex justify-content-between'>
        <h4>{{ title }}</h4>
        <p class='mt-0 small-text align-baseline d-inline'>Step {{currentStep}} of 2: {{currentStepText}}</p>
      </div>
    </template>

    <template v-slot:main-content>
      <FormulateForm v-if="!isDuplicating" :values="formValues" @submit="submitScenarioCreation" #default="{ isLoading, hasErrors }">
        <div v-if='currentStep === 1'>
          <FormulateInput
            name="scenario_name"
            label="Scenario Name"
            v-model='newScenarioName'
            validation="required"
            element-class='mt-0'
            input-class='border' />
          <span class="details-note mt-1">Use a descriptive name to easily identify the scenario.</span>
          <FormulateInput 
            v-if="scenarioStudyRequired"
            name="scenario_study_id"
            label="Study"
            type="select"
            element-class="mt-0"
            :options="scenarioStudyOptions"
            v-model="selectedScenarioStudyId" />
          <span class="details-note mt-1">Please select a study for this scenario.</span>
          <div v-if='!companyIsRWDI && !userIsSuperuser && !isUpdateModal'>
            <label class='small-label'>Job Level</label>
            <div class='d-flex flex-row no-top-margin-all-descendants level-radios'>
              <b-spinner small v-if="jobLevelOptionsIsLoading" />
              <div 
                v-for='(jobLevel, i) in jobLevelOptions' 
                :key='jobLevel.name' 
                class='w-50 border rounded py-3 d-flex flex-row'
                :class='[(i % 2) === 0 ? "mr-2" : "ml-2", selectedJobLevel === jobLevel.name ? "border-primary shadow-sm" : null]'
                @click='setSelectedJobLevel(jobLevel.name)'>
                <FormulateInput
                  type='radio'
                  name='job_level'
                  :value='jobLevel.name'
                  v-model='selectedJobLevel'
                  :disabled='isUpdateModal'
                  class='px-3 align-self-center'
                  validation="required"
                  :decorator-class='selectedJobLevel === jobLevel.name ? "border-primary formulate-input-element-decorator m-0" : "formulate-input-element-decorator m-0"'/>
                <div class='d-flex flex-column'>
                  <div 
                    class='font-weight-bold'
                    :class='selectedJobLevel === jobLevel.name ? "text-primary" : null'>
                    {{ jobLevel.name }}
                  </div>
                  <div class='small-text pr-4'>{{ jobLevel.description }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span class="mt-2" v-if="jobLevelOptionsIsLoading || metricsLoading">
              Loading list of available analysis types
              <b-spinner small class="ml-2" />
            </span>
            
            <FormulateInput
            v-else
            v-show="!isUpdateModal"
            type='radio'
            name='job_level'
            label='Job Level'
            :options='jobLevelOptions.map(level => level.name)'
            v-model='selectedJobLevel'
            :disabled='isUpdateModal'
            element-class='mt-0'
            input-class='d-inline-block px-2 my-0 no-top-margin-all-descendants'
            validation="required"/>
          </div>
          <FormulateInput
            v-if='selectedJobLevel'
            v-show="!isUpdateModal"
            label='Analysis selection'
            type='group'
            :repeatable='true'
            v-model='selectedAnalysisTypesWithCriteria'
            add-label='+ Add complementary analysis'
            :minimum='isUpdateModal ? selectedAnalysisTypesWithCriteria.length : 1'
            :limit='isUpdateModal ? selectedAnalysisTypesWithCriteria.length : maxAnalysisTypes'
            class='no-top-margin-all-descendants mt-3'
            @repeatableAdded="analysisTypeAdded"
            #default='{ index }'>
            <FormulateInput
              type='select'
              name='analysis_type'
              :label='index === 0 ? "Primary" : `Complementary ${index}`'
              :options='analysisTypeOptions(index)'
              @change='setSelectedAnalysisType(index)'
              :disabled='isUpdateModal'
              element-class='mt-0 formulate-input-element'
              input-class='d-inline-block px-2 my-0'
              label-class='text-secondary' />
            <b-spinner small v-if="metricsLoading"></b-spinner>
            <FormulateInput v-if="!metricsLoading && showAnalysisSelection"
              type='checkbox'
              name='criteria'
              label='Metrics:'
              :options='criteriaOptionsForAnalysisType(index)'
              :disabled='isUpdateModal'  
              element-class='mt-0 d-inline-block mb-0'
              input-class='d-inline-block px-2 my-0 mt-2'
              @input="(e) => disableIncompatibleCriteriaChoices(index, e)"
              label-class='d-inline-block text-secondary' 
              />
           </FormulateInput>
          <span v-if="!jobLevelOptionsIsLoading && !isUpdateModal" class="details-note mt-1">For more information on choosing the correct job type, please follow this link <a href="https://help.orbitalstack.com/choosing-ai-engine-vs-cfd-engine/" target="_blank">Learn more</a></span>
          
        </div>
        <div v-if='currentStep === 2'>
          <section class='no-top-margin-all-descendants'>
            <label>Scenario Configuration Summary</label>
            <ul class='small-text pl-3'>
              <li>
                <span class='mr-1 font-weight-bold'>Scenario name:</span> 
                <span>{{ newScenarioName }}</span>
              </li>
              <li>
                <span class='mr-1 font-weight-bold'>Job level:</span> 
                <span>{{ selectedJobLevel }}</span>
              </li>
              <li v-for='(typeWithCriteria, index) in expandedSelectedAnalysisTypesWithCriteria' :key='typeWithCriteria.__id'>
                <span class='mr-1 font-weight-bold'>
                  {{ index === 0 ? 'Primary analysis' : `Complementary analysis ${index}` }}:
                </span>
                <span>
                  {{ typeWithCriteria.analysis_type }} - Metrics:
                  <span v-for='(criterion, i) in typeWithCriteria.criteria' :key='criterion'>
                    {{ criterion }}{{ (i+1) != typeWithCriteria.criteria.length ? ',' : null}}
                  </span>
                </span>
              </li>
            </ul>
          </section>
          <label class='formulate-input'>This will cost {{ simulationCreditCost }} {{ creditType }} credits</label>
          <span class='details-note'>
            You will not be charged right now. This is an estimate, and the availability of credits will not be impacted in this step.
            All the costs will be displayed further on simulation submission.
          </span>
          <div class='d-flex flex-row subscription-radios'>
            <div
              class='d-flex flex-row w-50 border rounded mr-2 py-3 pr-2'
              :class="selectedSubscriptionType === 'MONTHLY' ? 'border-primary shadow-sm' : ((selectedSimulationType === 'CFD' || !loggedInUser.subscription) ? 'radio-button-disabled' : '')"
              @click="setSelectedSubscriptionType('MONTHLY')">
              <FormulateInput
                type='radio'
                name='subscription_type'
                value='MONTHLY'
                v-model='selectedSubscriptionType'
                :disabled='isUpdateModal || selectedSimulationType === "CFD" || !loggedInUser.subscription'
                class='px-3 align-self-center m-0'
                :decorator-class="selectedSubscriptionType ==='MONTHLY' ? 'border-primary formulate-input-element-decorator m-0' : 'formulate-input-element-decorator m-0'"/>
              <div class='d-flex flex-column m-0 align-self-center'>
                <span
                  class='font-weight-bold'
                  :class="selectedSubscriptionType === 'MONTHLY' ? 'text-primary' : ''">
                  Use my subscription
                </span>
                <span
                  class='mt-0 small-text'
                  v-if='monthlySubscription && selectedSimulationType === "ML"'>
                  {{ subscriptionAICreditsAvailable }} of {{ subscriptionAICreditsTotal }} AI credits available
                </span>
                <span
                  class='mt-0 font-weight-light small-text'
                  v-if='monthlySubscription && selectedSimulationType === "ML"'>
                  Credits will be renewed on {{ monthlySubscriptionRenewalDate }}
                </span>
                <span
                  class='mt-0 small-text'
                  v-if='selectedSimulationType === "CFD"'>
                  <b-icon icon='info-circle'></b-icon>
                  CFD simulations are available for pay-per-use only.
                </span>
                <span
                  class='mt-0 small-text'
                  v-else-if='!loggedInUser.subscription'>
                  <b-icon icon='info-circle'></b-icon>
                  You don't have an active monthly plan. You must proceed with the pay-per-use option.
                </span>
              </div>
            </div>
            <div
              class='d-flex flex-row mt-0 w-50 border rounded ml-2 py-3 pr-2'
              :class="selectedSubscriptionType === 'ONE-TIME' ? 'border-primary shadow-sm' : ''"
              @click="setSelectedSubscriptionType('ONE-TIME')">
              <FormulateInput
                type='radio'
                name='subscription_type'
                value='ONE-TIME'
                v-model='selectedSubscriptionType'
                :disabled='isUpdateModal'
                class='px-3 align-self-center m-0'
                :decorator-class="selectedSubscriptionType ==='ONE-TIME' ? 'border-primary formulate-input-element-decorator m-0' : 'formulate-input-element-decorator m-0'"/>
              <div class='d-flex flex-column m-0'>
                <span
                  class='font-weight-bold'
                  :class="selectedSubscriptionType === 'ONE-TIME' ? 'text-primary' : ''">
                  Use {{oneTimeSubscriptionTierAvailable}} credits
                </span>
                <span class='mt-0 small-text'>{{ prepaidCreditsAvailable }} {{oneTimeSubscriptionTierAvailable}} {{ creditType }} credits available</span>
                <span class='mt-0 font-weight-light small-text'>The cost to acquire more credits is ${{ additionalCreditCost }} per  {{ creditType }} credit</span>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="submit-container" v-if="canCreateScenario">
          <custom-button theme='ghost-button' type="button" @click="dismissSuccessfully(null)" v-if='currentStep === 1'>Cancel</custom-button>
          <custom-button theme='ghost-button' type="button" @click="goToStepOne" v-if='currentStep === 2'>Previous</custom-button>
          <div class="submit-button-container">
            <div class="loading-spinner-container"><b-spinner v-if="isLoading" class="mr-2 mt-2" small variant="primary"/></div>
            <div v-if='currentStep === 1'  id='step-one-tool-tip'>
              <b-button 
                @click='goToStepTwo'
                :disabled='nextButtonDisabled()' 
                variant='primary'>
                Next
              </b-button>
            </div>
            <div id='scenario-creation-button' v-if='currentStep === 2'>
              <FormulateInput
                type="submit"
                :label="createButtonLabel"
                :error="errorCreatingScenario ? 'Error creating scenario' : null"
                :disabled="hasErrors || isLoading" /> 
              <b-tooltip 
                target='scenario-creation-button' 
                triggers='hover'
                v-if='!newScenarioName.trim()'>
                Scenario name cannot be blank.
              </b-tooltip>
            </div>
          </div>
        </div>
      </FormulateForm>
      <FormulateForm v-if="isDuplicating" :values="formValues" @submit="submitScenarioCreation" #default="{ isLoading, hasErrors }">
        <div v-if='currentStep === 1'>
          <FormulateInput
            name="scenario_name"
            label="Scenario Name"
            v-model='newScenarioName'
            validation="required"
            element-class='mt-0'
            input-class='border' />
          <span class="details-note mt-1">Use a different and descriptive name to easily identify the scenario. Two scenarios can't have the same name.</span>
        </div>
        <div v-if='currentStep === 2'>
          <section class='no-top-margin-all-descendants'>
            <ul class='pl-3'>
              <li>
                <span class='mr-1 font-weight-bold'>Duplicate scenario name:</span> 
                <span>{{ newScenarioName }}</span>
                <br>
                <span class="details-note mt-1">This process can take some time depending on the amount of assets your scenario has, please keep the tab open, the new scenario will be loaded when the process is finished.</span>
              </li>
            </ul>
          </section>
          <label class='formulate-input'>This will cost {{ simulationCreditCost }} {{ creditType }} credits</label>
          <span class='details-note'>
            You will not be charged right now. This is an estimate, and the availability of credits will not be impacted in this step.
            All the costs will be displayed further on simulation submission.
          </span>
          <div class='d-flex flex-row subscription-radios'>
            <div
              class='d-flex flex-row w-50 border rounded mr-2 py-3 pr-2'
              :class="selectedSubscriptionType === 'MONTHLY' ? 'border-primary shadow-sm' : ((selectedSimulationType === 'CFD' || !loggedInUser.subscription) ? 'radio-button-disabled' : '')"
              @click="setSelectedSubscriptionType('MONTHLY')">
              <FormulateInput
                type='radio'
                name='subscription_type'
                value='MONTHLY'
                v-model='selectedSubscriptionType'
                :disabled='isUpdateModal || selectedSimulationType === "CFD" || !loggedInUser.subscription'
                class='px-3 align-self-center m-0'
                :decorator-class="selectedSubscriptionType ==='MONTHLY' ? 'border-primary formulate-input-element-decorator m-0' : 'formulate-input-element-decorator m-0'"/>
              <div class='d-flex flex-column m-0 align-self-center'>
                <span
                  class='font-weight-bold'
                  :class="selectedSubscriptionType === 'MONTHLY' ? 'text-primary' : ''">
                  Use my subscription
                </span>
                <span
                  class='mt-0 small-text'
                  v-if='monthlySubscription && selectedSimulationType === "ML"'>
                  {{ subscriptionAICreditsAvailable }} of {{ subscriptionAICreditsTotal }} AI credits available
                </span>
                <span
                  class='mt-0 font-weight-light small-text'
                  v-if='monthlySubscription && selectedSimulationType === "ML"'>
                  Credits will be renewed on {{ monthlySubscriptionRenewalDate }}
                </span>
                <span
                  class='mt-0 small-text'
                  v-if='selectedSimulationType === "CFD"'>
                  <b-icon icon='info-circle'></b-icon>
                  CFD simulations are available for pay-per-use only.
                </span>
                <span
                  class='mt-0 small-text'
                  v-else-if='!loggedInUser.subscription'>
                  <b-icon icon='info-circle'></b-icon>
                  You don't have an active monthly plan. You must proceed with the pay-per-use option.
                </span>
              </div>
            </div>
            <div
              class='d-flex flex-row mt-0 w-50 border rounded ml-2 py-3 pr-2'
              :class="selectedSubscriptionType === 'ONE-TIME' ? 'border-primary shadow-sm' : ''"
              @click="setSelectedSubscriptionType('ONE-TIME')">
              <FormulateInput
                type='radio'
                name='subscription_type'
                value='ONE-TIME'
                v-model='selectedSubscriptionType'
                :disabled='isUpdateModal'
                class='px-3 align-self-center m-0'
                :decorator-class="selectedSubscriptionType ==='ONE-TIME' ? 'border-primary formulate-input-element-decorator m-0' : 'formulate-input-element-decorator m-0'"/>
              <div class='d-flex flex-column m-0'>
                <span
                  class='font-weight-bold'
                  :class="selectedSubscriptionType === 'ONE-TIME' ? 'text-primary' : ''">
                  Use {{oneTimeSubscriptionTierAvailable}} credits
                </span>
                <span class='mt-0 small-text'>{{ prepaidCreditsAvailable }} {{oneTimeSubscriptionTierAvailable}} {{ creditType }} credits available</span>
                <span class='mt-0 font-weight-light small-text'>The cost to acquire more credits is ${{ additionalCreditCost }} per  {{ creditType }} credit</span>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="submit-container" v-if="canCreateScenario">
          <custom-button theme='ghost-button' type="button" @click="dismissSuccessfully(null)" v-if='currentStep === 1'>Cancel</custom-button>
          <custom-button theme='ghost-button' type="button" @click="goToStepOne" v-if='currentStep === 2'>Previous</custom-button>
          <div class="submit-button-container">
            <div class="loading-spinner-container"><b-spinner v-if="isLoading" class="mr-2 mt-2" small variant="primary"/></div>
            <div v-if='currentStep === 1'  id='step-one-tool-tip'>
              <b-button 
                @click='goToStepTwo'
                :disabled='nextButtonDisabled()' 
                variant='primary'>
                Next
              </b-button>
              <b-tooltip 
    target='step-one-tool-tip' 
    triggers='hover'
    v-if='isDuplicateNameSame'>
    The new scenario name must be different from the original.
  </b-tooltip>
            </div>
            <div id='scenario-creation-button' v-if='currentStep === 2'>
              <FormulateInput
                type="submit"
                :label="createButtonLabel"
                :error="errorCreatingScenario ? 'Error creating scenario' : null"
                :disabled="hasErrors || isLoading" /> 
              <b-tooltip 
                target='scenario-creation-button' 
                triggers='hover'
                v-if='!newScenarioName.trim()'>
                Scenario name cannot be blank.
              </b-tooltip>
            </div>
          </div>
        </div>
      </FormulateForm>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Modal, CustomButton } from 'rwdi-widgets';
import { ADD_SCENARIO } from '@/constants/permissions';
import { GET_COMPANY_AVAILABLE_SUBSCRIPTIONS } from '@/store/actions/permissions';
import { GET_LOGGED_IN_USER } from '@/store/actions/auth';

export default {
  name: 'CreateScenarioModal',
  components: {
    Modal,
    CustomButton
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    projectId: {
      type: Number,
      required: false
    },
    formValues: {
      type: Object,
      required: false
    },
    configurationId: {
      type: Number,
      required: false
    },
    studyId: {
      type: Number,
      required: false
    },
    simulationId: {
      type: Number,
      required: false
    },    
    isDuplicating: {
      type: Boolean,
      default: false
    },
    scenarioToDuplicate: {
      type: Object,
      default: null
    },
  },
  computed: {
    isDuplicateNameSame() {
      return this.isDuplicating && this.newScenarioName.trim() === this.scenarioToDuplicate.scenario.trim();
    },
    showAnalysisSelection(){
      return this.selectedJobLevel != 'Import Results';
    },
    scenarioStudyRequired() {
      return this.project.studies.length > 1 && !this.selectedStudy;
    },
    scenarioStudyId() {
      if(this.project.studies.length == 1) {
        return this.project.studies[0].id;
      } else if(this.selectedStudy) {
        return this.selectedStudy.id;
      } else {
        return this.selectedScenarioStudyId;
      }
    },
    scenarioStudyOptions() {
      return this.project.studies.map(study => {
        return {
          label: study.name,
          value: study.id
        };
      });
    },
    project() {
      let project;
      project = this.$store.getters['project/selectedProject'];
      if (project) {
        return project;
      } else {
        let allProjects = this.$store.getters['project/allProjects'];
        project = allProjects.find(proj => proj.id === this.projectId);
        return project;
      }
    },
    metSourceError() {
      return this.project.met_sources.length > 0 && this.project.met_sources.filter(x => x.error == null).length == 0;
    },
    selectedMetSource() {
      let selected = null;
      if(this.selectedMetSourceId) {
        selected = this.project.met_sources.find(source => source.id == this.selectedMetSourceId);
      } else if (this.metSourceOptions.length === 1) {
        selected = this.project.met_sources.find(source => source.id == this.metSourceOptions[0].value);
      } else {
        selected = null;
      }

      return selected;
    },
    metSourceOptions() {
      let options = [];
      this.project.met_sources.forEach(source => {
        if (source.error == null) {
          if(source.wind_data_type === 'MEASURED') {
            options.push({
              value: source.id,
              label: source.station.name
            });
          } else if (source.wind_data_type === 'MODELLED') {
            options.push({
              value: source.id,
              label: 'Modelled at site'
            });
          } else if (source.wind_data_type === 'CUSTOM') {
            if(source?.custom_met_data?.label){
              options.push({
                value: source.id,
                label: source.custom_met_data.label
              });
            }else{
              options.push({
                value: source.id,
                label: decodeURI(String(source.custom_met_data.file).split('?')[0].split('/').pop())
              });
            }
          } else if (source.wind_data_type.startsWith('FUTURE')) {
            let tokens = source.wind_data_type.split('-');
            options.push({
              value: source.id,
              label: `Future Met: scenario ${tokens[1]}, year ${tokens[2]}`
            });
          }
        }
      });
      return options;
    },
    maxAnalysisTypes() {
      if(this.selectedAnalysisTypesWithCriteria.length === 0) {
        return 1;
      } else {
        let primaryAnalysisType = this.selectedAnalysisTypesWithCriteria[0].analysis_type;
        if(!primaryAnalysisType) return 1;
        let selectedGroup = this.companyJobTypes.find(type => type.analysis_type === primaryAnalysisType && type.level.name === this.selectedJobLevel).group;
        let numberOfAnalysisTypesInGroup = this.companyJobTypes.filter(type => type.group === selectedGroup && type.level.name === this.selectedJobLevel).length;
        return numberOfAnalysisTypesInGroup;
      }
    },
    complementaryAnalysisTypesAvailable() {
      return this.maxAnalysisTypes > 1;
    },
    currentStepText() {
      switch(this.currentStep) {
      case 1:
        return 'Configuration';
      case 2:
        if(this.isDuplicating){
          return 'Summary';
        }
        return 'Summary and payment';
      default:
        return '';
      }
    },
    canCreateScenario() {
      return (this.allowedPermissions || {})[ADD_SCENARIO];
    },
    title() {
      if(this.isDuplicating){
        return 'Duplicate Scenario';
      }
      const scenarioString = `${this.formValues == null ? 'New' : 'Update'} Scenario`;
      return scenarioString;
    },
    createButtonLabel() {
      return this.formValues == null ? 'Create' : 'Update';
    },
    maxFileSize() {
      return this.selectedSimulationType === 'ML' ? 20971520 : 104857600;
    },
    companyIsRWDI() {
      return this.userCompany?.name === 'RWDI';
    },
    userIsSuperuser() {
      return this.loggedInUser?.is_superuser;
    },
    hasThermalComfortJobType() {
      return this.selectedJobTypesWithCriteria.some(x => {
        return this.jobTypes.some(y => x.job_type === y.id && y.analysis_type === 'TC');
      });
    },
    hasCladdingJobType() {
      return this.selectedJobTypesWithCriteria.some(x => {
        return this.jobTypes.some(y => x.job_type === y.id && y.analysis_type === 'CLADDING');
      });
    },
    companyJobTypes() {
      let typeEnabled, hasRequiredModule;
      return this.jobTypes.filter(type => {
        typeEnabled = (this.companyIsRWDI && type.rwdi) || (!this.companyIsRWDI && type.os_direct);
        hasRequiredModule = (!type.required_module) || (type.required_module && this.hasModuleById(type.required_module));
        return typeEnabled && hasRequiredModule || this.userIsSuperuser;
      });
    },
    jobLevelOptions() {
      let jobLevels = [];
      let levelExists;
      this.companyJobTypes.forEach(type => {
        levelExists = jobLevels.some(level => level.name === type.level.name);
        if(!levelExists) {
          jobLevels.push(type.level);
        }
      });
      return jobLevels.sort((a, b) => {
        if (!a.sort_order) a.sort_order = 0;
        if (!b.sort_order) b.sort_order = 0;
        
        return a.sort_order - b.sort_order;
      });
    },
    jobLevelOptionsIsLoading() {
      return this.jobLevelOptions.length == 0;
    },
    metricsLoading() {
      return this.criteria.length == 0;
    },
    monthlySubscription() {
      let subscriptionId = this.loggedInUser.subscription;
      if(subscriptionId) {
        return this.companyAvailableSubscriptions.find(sub => sub.id == subscriptionId);
      } else {
        return null;
      }
    },
    subscriptionAICreditsAvailable() {
      if(this.monthlySubscription) {
        return this.monthlySubscription.subscription_type.monthly_wind_directions - this.monthlySubscription.credits_used_this_month;
      } else {
        return 0;
      }
    },
    subscriptionAICreditsTotal() {
      if(this.monthlySubscription) {
        return this.monthlySubscription.subscription_type.monthly_wind_directions;
      } else {
        return 0;
      }
    },
    simulationCreditCost() {
      let cost;
      let credit_type;
      
      if (this.isDuplicating){
        this.updateselectedSimulationType();
      }
      if (this.selectedSimulationType === 'IMPORT') {
        credit_type = 'NONE';
      } else if (this.selectedSimulationType === 'ML') {
        credit_type = 'AI';
      } else if (this.selectedSimulationType === 'CFD') {
        credit_type = 'CFD';
      }
      // base cost
      cost = this.simulationPrices.find(price => {
        return price.type === 'base' && price.credit_type === credit_type;
      })?.credits_required;
      

      // add-ons
      if(this.hasThermalComfortJobType) {
        cost += this.simulationPrices.find(price => price.code === 'TC' && price.credit_type === credit_type).credits_required;
      }

      if(this.hasCladdingJobType) {
        cost += this.simulationPrices.find(price => price.code === 'CL' && price.credit_type === credit_type).credits_required;
      }

      return cost || 0;
    },
    monthlySubscriptionRenewalDate() {
      if(this.monthlySubscription) {
        let today = new Date();
        let rollover = this.monthlySubscription.monthly_roll_over_day;
        if(today.getDate() >= rollover) {
          // next month
          return `${today.getFullYear()}/${today.getMonth()+2}/${rollover}`;
        } else {
          // this month
          return `${today.getFullYear()}/${today.getMonth()+1}/${rollover}`;
        }
      } else {
        return null;
      }
    },
    prepaidCreditsAvailable() {
      return this.companyAvailableSubscriptions.reduce((sum, sub) => {
        if(sub.subscription_type.type === 'ONE-TIME' && sub.subscription_type.credit_type === this.creditType) {
          let creditsAvailable = sub.pay_per_use_credits - sub.total_credits_used;
          return sum + creditsAvailable;
        } else {
          return sum;
        }
      }, 0);
    },
    creditType() {
      if(this.selectedSimulationType === 'ML') {
        return 'AI';
      } else if (this.selectedSimulationType === 'CFD') {
        return 'CFD';
      } else {
        return null;
      }
    },
    additionalCreditCost() {
      return this.companySubscriptionTypePrices.find(price => price.subscription_type.credit_type === this.creditType && price.subscription_type.tier === 'Pay-per-use')?.price;
    },
    monthlySubscriptionDisabled() {
      return !this.loggedInUser.subscription || this.selectedSimulationType === 'CFD';
    },
    isUpdateModal() {
      return !!this.formValues;
    },
    oneTimeSubscriptionTierAvailable() {
      let freeTrialCredits = this.companyAvailableSubscriptions.find(sub => sub.subscription_type.tier === 'Free');
      if(freeTrialCredits && this.selectedSimulationType === 'ML') {
        return 'free trial';
      }

      let prepaidCFDcredits = this.companyAvailableSubscriptions.find(sub => {
        return sub.subscription_type.tier === 'Pay-per-use' && sub.subscription_type.credit_type === 'CFD';
      });
      if(prepaidCFDcredits && this.selectedSimulationType === 'CFD') {
        return 'prepaid';
      }

      let prepaidAIcredits = this.companyAvailableSubscriptions.find(sub => {
        return sub.subscription_type.tier === 'Pay-per-use' && sub.subscription_type.credit_type === 'AI';
      });
      if(prepaidAIcredits && this.selectedSimulationType === 'ML') {
        return 'prepaid';
      }

      return 'pay-per-use';
    },
    ...mapGetters([
      'allowedPermissions',
      'loggedInUser',
      'userCompany',
      'companySubscriptions',
      'companyAvailableSubscriptions',
      'simulationPrices',
      'companySubscriptionTypePrices']),
    ...mapGetters('project', ['allProjects', 'uploadUuid', 'jobTypes', 'criteria', 'selectedStudy']),
  },
  data() {
    return {
      disabledCriteria: [],
      selectedAnalysisType: null,
      modalIsVisible: false,
      currentPage: 'geometrySubmission',
      currentStep: 1,
      selectedSimulationType: 'ML',
      selectedJobLevel: null,
      selectedAnalysisTypesWithCriteria: [],
      expandedSelectedAnalysisTypesWithCriteria: [],
      selectedJobTypesWithCriteria: [],
      selectedMetSourceId: null,
      newScenarioName: '',
      selectedSubscriptionType: 'ONE-TIME',
      currentConfigurationId: this.configurationId,
      currentStudyId: this.studyId,
      currentSimulationId: this.simulationId,
      errorCreatingScenario: false,
      selectedScenarioStudyId: null,
      // These 3 variables should pull their initial values from the formValues prop but when this data object is initialized the formValues aren't set yet (because they wait
      // for the geometry to load).  Instead these are defaulted to null and when a scenario is updated (which is what these are used for) we check for the values inside formValues
      // first, and if it's not there then check currentConfigurationName/Study/Note, which are set when a scenario is created.  This lets the 'update scenario' logic work if the user
      // is creating the scenario all the way through in 1 go (create is called to submit geometry, update to submit ML Parameters) or if the user is resuming a previously started
      // scenario submission (update is called to re-save geometry and update ML Parameters)
      currentConfigurationName: null ,
      currentConfigurationSimulationType: null
    };
  },
  async created() {
    await this.$store.dispatch(GET_COMPANY_AVAILABLE_SUBSCRIPTIONS);
    await this.$store.dispatch(GET_LOGGED_IN_USER);
    this.initializeFormData();
  },
  async updated() {
    this.initializeFormData();
  },
  methods: {
    updateselectedSimulationType(){
      if (this.isDuplicating){        
        if (this.scenarioToDuplicate.type === 'AI'){
          this.selectedSimulationType = 'ML';
        } else {
          this.selectedSimulationType = this.scenarioToDuplicate.type;
        }
      }
    },
    hasModuleByName(moduleName) {
      let companyHasModule = !!this.userCompany.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    hasModuleById(moduleId) {
      let companyHasModule = !!this.userCompany.modules.find(x => x.id === moduleId);
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.id === moduleId);
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    async disableIncompatibleCriteriaChoices(index, selectedCriteriaNames) {
      //enable anything that's been previously disabled
      for (let disabled_item_id of this.disabledCriteria) {
        let disabled_item_label = document.querySelector(`[id^="${disabled_item_id}"]`);
        if(disabled_item_label) {
          disabled_item_label.parentElement.classList.remove('disabled');
          this.removeTooltip(disabled_item_label.parentElement.parentElement); // the label's parent is disabled and the label's grandparent contains the tooltip
        }
      }

      this.disabledCriteria.length = 0;  //reset the array since we've just enabled everything in it

      //disable any that are incompatible with the selected criteria
      for (let selectedCriteria of selectedCriteriaNames) {

        //go through the master list of criteria and find the object that corresponds to the selected criteria name and selected job level (SPMV*, for example, has 2 entries: 1 for AI and 1 for CFD)
        let matching_criteria_object = this.criteria.find(criterion => criterion.name == selectedCriteria && criterion.required_level.includes(this.selectedJobLevel));

        if (matching_criteria_object?.incompatible_criteria) {  //matching_criteria_object will be null if the selected criteria is actually a criteria group.  At the moment support for incompatible criteria groups has not been implemented and is not needed.
          //loop through the selected criteria options and for each,check if any of the other criteria options are in the selected criteria's list of incomptatible criteria.  If so, disable those options so they can't be clicked
          for (let incompatible_criteria_entry of matching_criteria_object.incompatible_criteria) {
            if (this.criteriaOptionsForAnalysisType(index).includes(incompatible_criteria_entry)) {
              await this.$nextTick();  //when the selected analysis type changes this method fires before the new analysis type's criteria list is available.  nextTicket lets the UI re-render before proceeding

              //the label element of the checkbox contains the criteria name and has a _label at the end of it, so use that to get the element, then 
              //get the elements parent, which is a div containing checkbox control.  Then disable it.
              let label_element = document.querySelector(`[id$="${incompatible_criteria_entry}_label"]`);
              label_element.parentElement.classList.add('disabled');
              this.disabledCriteria.push(label_element.id);  //store the disabled items so they can be re-enabled
              
              //the label's parent has been disabled, so mouse events won't propagate, but we can add a tooltip to the control's grandparent, which is another contain for the control.  This allows
              //the mouse event for the tooltip to work while maintaining a disabled input/label
              this.addTooltip(label_element.parentElement.parentElement, `uncheck "${selectedCriteria}" to enable this metric`);
            }
          }
        }
        
      }
    },
    addTooltip(element, tooltip) {
      let tooltipSpan = document.createElement('span');
      tooltipSpan.classList.add('criteria-tooltip');
      let tooltipText = document.createTextNode(tooltip);
      tooltipSpan.appendChild(tooltipText);
      element.appendChild(tooltipSpan);

      element.addEventListener('mouseover', this.showCriteriaTooltip);
      element.addEventListener('mouseout', this.hideCriteriaTooltip);
    },
    removeTooltip(element) {
      let tooltipSpan = element.querySelector('.criteria-tooltip');
      element.removeChild(tooltipSpan);
      element.removeEventListener('mouseover', this.showCriteriaTooltip);
      element.removeEventListener('mouseout', this.hideCriteriaTooltip);
    },
    showCriteriaTooltip() {
      let tooltip = document.querySelector('.criteria-tooltip');
      tooltip.style.display = 'block';
    },
    hideCriteriaTooltip() {
      let tooltip = document.querySelector('.criteria-tooltip');
      tooltip.style.display = 'none';
    },
    analysisTypeOptions(index) {
      if (index === undefined) return [];
  
      let analysisTypeOptions = [];
      let sortedList = this.companyJobTypes.sort((a, b) => (this.sortOrderOrDefault(a.sort_order) > this.sortOrderOrDefault(b.sort_order)) ? 1 : -1);
      let currentAnalysisType = this.selectedAnalysisTypesWithCriteria[index]?.analysis_type;
      let selectedAnalysisTypes = this.selectedAnalysisTypesWithCriteria.map(selection => selection.analysis_type);

      if (index === 0) {
        //primary analysis type.  Display all
        sortedList.forEach(type => {
          if(type.level.name === this.selectedJobLevel && type.can_be_primary_analysis === true
            && (!selectedAnalysisTypes.includes(type.analysis_type) || type.analysis_type === currentAnalysisType)) {
            analysisTypeOptions.push({
              label: type.label,
              value: type.analysis_type
            });
          }
        });
      } else {
        //complementary analysis type, only display those in the same group as the primary.  filter out the primary one
        let selectedGroup = selectedAnalysisTypes.length ? this.selectedAnalysisTypesWithCriteria[0].group : null;
        sortedList.forEach(type => {
          if(type.level.name === this.selectedJobLevel 
            && (!selectedAnalysisTypes.includes(type.analysis_type) || type.analysis_type === currentAnalysisType)
            && (selectedGroup === type.group)) {
            analysisTypeOptions.push({
              label: type.label,
              value: type.analysis_type
            });
          }
        });
      }
      
      return analysisTypeOptions;
    },
    sortOrderOrDefault(sortOrder) {
      if (!sortOrder) {
        return 99999; 
      } else return sortOrder;
    },
    criteriaOptionsForAnalysisType(index) {
      if(this.selectedAnalysisTypesWithCriteria.length === 0 || index === undefined) return [];
      let analysisType = this.selectedAnalysisTypesWithCriteria[index].analysis_type;
      let criteriaOptions = [];
      this.criteria.forEach(criterion  => {
        if (!criterion.required_module || criterion.required_module && this.hasModuleById(criterion.required_module)) {
          if(criterion.analysis_type === analysisType && criterion.required_level.includes(this.selectedJobLevel)){
            if(!criterion.display_group) {
              criteriaOptions.push({'name': criterion.name, 'sort_order': this.sortOrderOrDefault(criterion.sort_order)});
            } else {
              let displayGroupPresent = criteriaOptions.find(c => c.name == criterion.display_group);
              if (!displayGroupPresent) {
                criteriaOptions.push({name: criterion.display_group, sort_order: this.sortOrderOrDefault(criterion.sort_order)});
              }
            }
          }
        }
      });
      const sortedCriteriaOptions = criteriaOptions.sort(function(a, b){return a.sort_order - b.sort_order ;});
      const criteriaOptionsList = sortedCriteriaOptions.map(function (criterion) {
        return criterion.name;
      });
      return criteriaOptionsList;
    },
    setSelectedJobLevel(jobLevel) {
      if(!this.isUpdateModal) this.selectedJobLevel = jobLevel;
    },
    setSelectedAnalysisType(index) {
      let selectedAnalysisType = this.selectedAnalysisTypesWithCriteria[index].analysis_type;
      this.selectedAnalysisType = selectedAnalysisType;
      let selectedJobType = this.companyJobTypes.find(type => type.analysis_type === selectedAnalysisType && type.level.name == this.selectedJobLevel);
      if(selectedJobType?.default_criteria_group?.length){
        this.selectedAnalysisTypesWithCriteria[index].criteria = selectedJobType.default_criteria_group;
      }else{
        this.selectedAnalysisTypesWithCriteria[index].criteria = selectedJobType.default_criteria;
      }
      this.selectedAnalysisTypesWithCriteria[index].group = selectedJobType.group;

      if (index === 0 ) {
        //primary analysis type changed.  check to see if complementary analysis types exist for the selected primary type.  If not
        //the remove any complementary types that had been added
        if (!this.complementaryAnalysisTypesAvailable) {
          this.selectedAnalysisTypesWithCriteria.length = 1;  //drop all but the first element of the list
        }

      }
    },
    async analysisTypeAdded(analysisTypesList) {
      await this.$nextTick();  //let this.selectedAnalysisTypesWithCriteria update with the newly added analysis type, then that type is used to get the default criteria
      let addedIndex = analysisTypesList.length - 1;
      this.setSelectedAnalysisType(addedIndex);
    },
    goToStepOne() {
      this.currentStep = 1;
    },
    goToStepTwo() {
      this.currentStep = 2;
    },
    nextButtonDisabled() {  
      if (this.jobLevelOptionsIsLoading) return true;
      if (this.metricsLoading) return true;
      if (this.isDuplicateNameSame) return true;
      return this.valuesMissing();
    },
    valuesMissing() {
      if (this.isDuplicating && this.newScenarioName.trim() && (this.newScenarioName != this.scenarioToDuplicate.scenario)){
        return false;
      }
      if (!this.newScenarioName.trim() || !this.selectedJobLevel || !this.selectedAnalysisTypesWithCriteria.length || this.isDuplicateNameSame) {
        return true;
      } else if (this.selectedAnalysisTypesWithCriteria.length) {
        for (const type_with_criteria of this.selectedAnalysisTypesWithCriteria) {
          if(!type_with_criteria.analysis_type || !type_with_criteria.criteria?.length) {
            return true; 
          } 
        }
      } else {
        return false;
      }
    },
    initializeFormData() {
      const getDefaultSimulationType = 'ML';
      
      if (this.loggedInUser.subscription) {
        this.selectedSubscriptionType = 'MONTHLY';
      } else {
        this.selectedSubscriptionType = 'ONE-TIME';
      }

      this.selectedSimulationType = this.formValues?.simulation_type || getDefaultSimulationType;
      if(this.formValues?.scenario_name) this.newScenarioName = this.formValues.scenario_name;
      if(this.formValues?.subscription_type) this.selectedSubscriptionType = this.formValues.subscription_type;
      if(this.formValues?.job_types_with_criteria) {
        this.selectedJobTypesWithCriteria = this.formValues.job_types_with_criteria;
        this.initializeAnalysisTypesWithCriteria();
      }
    },
    initializeAnalysisTypesWithCriteria() {
      let analysisTypeWithCriteria;
      let analysisTypesWithCriteria = [];
      let primaryJobTypeId = this.selectedJobTypesWithCriteria[0].job_type;
      let primaryJobType = this.companyJobTypes.find(type => type.id === primaryJobTypeId);
      this.selectedJobLevel = primaryJobType.level.name;
      this.selectedJobTypesWithCriteria.forEach(type_with_criteria => {
        analysisTypeWithCriteria = {
          analysis_type: this.companyJobTypes.find(type => type.id === type_with_criteria.job_type).analysis_type,
          criteria: type_with_criteria.criteria.map(criterion => this.criteria.find(c => c.id === criterion)?.name),
          group: this.companyJobTypes.find(type => type.id === type_with_criteria.job_type).group
        };
        analysisTypesWithCriteria.push(analysisTypeWithCriteria);
      });
      this.selectedAnalysisTypesWithCriteria = analysisTypesWithCriteria;
    },
    //Geometry form submitted. Create/Update or Duplicate the scenario
    async submitScenarioCreation(data) {
      if (this.currentStep !== 2 || this.valuesMissing()) {
        // Prevent invalid form submissions, e.g. ENTER key when there's incomplete forma data.
        return;
      }

      let scenarioCreationInfo = null;
      if (!this.currentConfigurationId) {
        if (this.isDuplicating){
          scenarioCreationInfo = await this.duplicateScenario(this.scenarioToDuplicate);
        } else {
          scenarioCreationInfo = await this.createScenario(data);
        }
        //set these 2 properties so that the next time the form is submitted an update is executed and not a create
        this.currentStudyId = scenarioCreationInfo?.studyId;
        this.currentConfigurationId = scenarioCreationInfo?.configurationId;
        this.currentSimulationId = scenarioCreationInfo?.simulationId;
      } else {
        scenarioCreationInfo = await this.updateScenario(data);
      }
      if (scenarioCreationInfo === null && !this.errorCreatingScenario) {
        this.dismissSuccessfully(null); // Only dismiss if there is no error
      } else {
        this.dismissSuccessfully(scenarioCreationInfo);
      }
    },
    async duplicateScenario(data) {
      try {
        const scenarioInformation = await this.storeDuplicateScenario({
          projectId: this.projectId,
          configurationId: data.configurationId,
          newScenarioName: this.newScenarioName,
          subscriptionType: this.selectedSubscriptionType
        });
        return scenarioInformation;
      } catch(e) {
        this.errorCreatingScenario = true;
        return null;
      }
    },
    async createScenario(geometryAndScenarioDetails) {
      try {
        const scenarioInformation = await this.storeCreateScenario({
          projectId: this.projectId,
          scenario: this.createScenarioFromFormValues(),
          formValues: geometryAndScenarioDetails
        });

        this.setSelectedSimulationType(geometryAndScenarioDetails['simulation_type']);
        return scenarioInformation;
      } catch(e) {
        this.errorCreatingScenario = true;
        return null;
      }
    },
    async updateScenario(geometryAndScenarioDetails) {
      try {
        await this.storeUpdateScenario({
          projectId: this.projectId,
          studyId: this.currentStudyId,
          configurationId: this.currentConfigurationId,
          scenario: this.createScenarioFromFormValues(geometryAndScenarioDetails),
          formValues: geometryAndScenarioDetails
        });
        return {};
      } catch (error) {
        return null;
      }
    },
    dismissSuccessfully(scenarioInformation) {
      this.$emit('close-modal', scenarioInformation);
    },
    createScenarioFromFormValues() {
      let scenarion_values = {
        name: this.newScenarioName,
        study_id: this.scenarioStudyId, 
        job_types_with_criteria: this.selectedJobTypesWithCriteria,
        simulation_type: this.selectedSimulationType,
        subscription_type: this.selectedSubscriptionType,
        simulation_id: this.currentSimulationId,
        met_source_id: this.selectedMetSourceId ? this.selectedMetSourceId : this.metSourceOptions[0]?.value
      };
      return scenarion_values;
    },
    setSelectedSubscriptionType(type) {
      if(type === 'MONTHLY') {
        if(!this.loggedInUser.subscription || this.selectedSimulationType === 'CFD') {
          return;
        }
      }
      if (this.isUpdateModal) {
        return;
      }
      this.selectedSubscriptionType = type;
    },
    ...mapActions({
      storeCreateScenario: 'project/createScenario',
      storeUpdateScenario: 'project/updateScenario',
      storeDuplicateScenario: 'project/duplicateScenario',
      setSelectedSimulationType: 'project/setSelectedSimulationType'
    })
  },
  watch: {
    showModal(newValue) {
      // reset modal
      if(!newValue) {
        this.selectedJobLevel = null;
        this.selectedAnalysisTypesWithCriteria = [];
        this.selectedJobTypesWithCriteria = [];
        this.newScenarioName = '';
        this.selectedSubscriptionType = 'ONE-TIME';
        this.selectedSimulationType = 'ML';
        this.currentStep = 1;
      }
      if(this.isDuplicating){
        this.newScenarioName = `${this.scenarioToDuplicate.scenario} Copy`;
      }
      this.modalIsVisible = newValue;
      this.currentPage = 'geometrySubmission';
    },
    selectedSimulationType(newValue) {
      if (this.isDuplicating){
        this.selectedSimulationType = this.scenarioToDuplicate.type;
      }
      if (newValue === 'CFD') this.selectedSubscriptionType = 'ONE-TIME';
    },
    selectedJobLevel(newValue) {
      if(newValue) {
        this.selectedJobTypesWithCriteria = [];
        let firstAnalysisType = this.analysisTypeOptions(0)[0];
        let firstJobType = this.companyJobTypes.find(type => type.level.name === newValue && type.analysis_type === firstAnalysisType.value);
        this.selectedAnalysisTypesWithCriteria = [{ analysis_type: firstAnalysisType.value, criteria: firstJobType.default_criteria, group: firstJobType.group }];
        this.selectedAnalysisType = firstAnalysisType.value;
        let creditType = this.companyJobTypes.find(type => type.level.name === newValue).credit_type;
        if (creditType === 'NONE') {
          this.selectedSimulationType = 'IMPORT';
        } else if (creditType === 'AI') {
          this.selectedSimulationType = 'ML';
        } else if (creditType === 'CFD') {
          this.selectedSimulationType = 'CFD';
        }
      }
    },
    selectedAnalysisTypesWithCriteria(newValue) {
      let selectedJobTypesWithCriteria = [];
      let expandedSelectedAnalysisTypesWithCriteria = [];
      let jobType, criteria, isDisplayGroup, criteriaInDisplayGroup;

      newValue.forEach(analysisWithCriteriaOrDisplayGroup => {
        criteria = [];
        if(analysisWithCriteriaOrDisplayGroup.criteria) {
          analysisWithCriteriaOrDisplayGroup.criteria.forEach(criterion => {
            isDisplayGroup = this.criteria.find(c => c.display_group == criterion && c.required_level.includes(this.selectedJobLevel));
            if(isDisplayGroup) {
              criteriaInDisplayGroup = this.criteria.filter(c => c.display_group == criterion && c.required_level.includes(this.selectedJobLevel));
              criteria = criteria.concat(criteriaInDisplayGroup.map(c=>c.name));
            } else {
              criteria.push(criterion);
            }
          });
        }
        expandedSelectedAnalysisTypesWithCriteria.push({
          analysis_type: analysisWithCriteriaOrDisplayGroup.analysis_type,
          criteria: criteria
        });
      });

      expandedSelectedAnalysisTypesWithCriteria.forEach(analysisWithCriteria => {
        jobType = this.companyJobTypes.find(type => type.analysis_type === analysisWithCriteria.analysis_type && type.level.name === this.selectedJobLevel);
        if(analysisWithCriteria.criteria) {
          criteria = analysisWithCriteria.criteria.map(criterion => {
            return this.criteria.find(c => c.name === criterion && c.required_level.includes(this.selectedJobLevel))?.id;
          });
        } else {
          criteria = [];
        }
        if(jobType?.id) {
          // using snake case since this will be the value saved to the back-end db
          selectedJobTypesWithCriteria.push({
            job_type: jobType.id,
            criteria: criteria
          });
        }
      });
      this.expandedSelectedAnalysisTypesWithCriteria = expandedSelectedAnalysisTypesWithCriteria;
      this.selectedJobTypesWithCriteria = selectedJobTypesWithCriteria; 
    }
  }
};
</script>

<style scoped>



.beta-annoucement {
  float: right;
}
.radio-button-disabled {
  opacity: 0.5;
}

.create-scenario-modal {
  overflow-y: auto;
}

.submit-container {
  display: flex;
}

.submit-container > * {
  margin-top: 0;
}

.loading-view {
  display: flex;
  align-items: center;
}

.formulate-input {
  margin-bottom: 0;
}

.small-text {
  font-size: 0.625rem;
}

.small-label {
  font-size: .9em;
}
</style>

<style>
.criteria-tooltip {
   display: none;
   background-color: rgb(103, 102, 102);
   color: rgb(255, 255, 255);
   font-size: 0.75rem;
   position: absolute;
   margin-top: -3.5rem;
   margin-left: 0.5rem;
   padding-left: 0.35rem;
   padding-right: 0.35rem;
   border-radius: 0.35rem;
}

[id^=checkbox-disabled] {
  opacity: 0.5;
}

.subscription-radios .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff !important;
}

.level-radios .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff !important;
}

.formulate-input .formulate-input-element {
  max-width: none;
}

.formulate-input[data-classification=button] button {
  background-color: var(--primary-blue);
  border: 0.063rem solid var(--primary-blue-dark);
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=button] button:active {
  background-color: var(--primary-blue);
  border: 0.063rem solid var(--primary-blue-dark);
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=file] .formulate-input-upload-area input:focus~.formulate-input-upload-area-mask, .formulate-input[data-classification=file] .formulate-input-upload-area input:hover~.formulate-input-upload-area-mask, .formulate-input[data-classification=file] .formulate-input-upload-area input[data-is-drag-hover]~.formulate-input-upload-area-mask {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.formulate-input[data-classification=file] .formulate-input-upload-area input:focus~.formulate-input-upload-area-mask:before, .formulate-input[data-classification=file] .formulate-input-upload-area input:hover~.formulate-input-upload-area-mask:before, .formulate-input[data-classification=file] .formulate-input-upload-area input[data-is-drag-hover]~.formulate-input-upload-area-mask:before {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
  background-color: var(--primary-blue);
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-add {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.content-container {
  overflow-y: auto;
  max-height: 90%;
}

.submit-container {
  display: flex;
  margin-top: 0;
  justify-content: space-between;
  align-items: center;
}

.submit-container > * {
  margin-top: 0;
}

.submit-button-container {
  display: flex;
}

.submit-button-container > * {
  margin: 0;
}

.loading-view {
  display: flex;
  align-items: center;
}

.details-note {
    color: #6d6d6d;
    font-size: .7em;
    font-weight: 400;
    line-height: 1.5
}

hr {
  height: 0.125rem;
  margin-bottom: 1.563rem;
  margin-top: 1.563rem;
}
</style>
