<template>
  <div class="report-container flex-container">
    <div class="report-selection-container" v-if="currentReport && isReportsMode">
      <h3 class="report-selection-title">Report</h3>
      <b-dropdown size="sm" variant="outline-primary bg-white border-white text-dark" class="mx-2 report-dropdown">
        <template #button-content>
          <div class="dd">
            <p>{{ currentReport.name }}</p>
          </div>
        </template>
        <b-dropdown-item-button 
          class="dropdown-button" 
          variant="dark" 
          v-for="report in reports" 
          :key="report.id" 
          @click="selectReport(report)">
          {{ report.name }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <div :id="`${setting}-embeded-pdf`"></div>
  </div>
</template>

<script>
import PDFObject from 'pdfobject';
import { mapGetters, mapActions } from 'vuex';
import { BDropdown, BDropdownItemButton } from 'bootstrap-vue';

export default {
  name: 'ReportContainer',
  components: {
    BDropdown,
    BDropdownItemButton
  },
  props: {
    setting: {
      type: String,
      required: true
    }
  },
  computed: {
    reports() {
      return this.selectedStudy ? this.selectedStudy.reports : []; 
    },
    report() {
      if (!this.studies) {
        return null;
      }
      const report = this.reports?.find(report => report?.name.toUpperCase() == this.currentReport?.name.toUpperCase());
      return !report ? null : report.report_file;
    },
    ...mapGetters('project', ['isReportsMode', 'isInsightsMode', 'currentInsight', 'studies', 'currentReport', 'viewerMode', 'selectedStudy'])
  },
  mounted() {
    if (this.report) {
      this.embedPdf(this.report, 1);
    }
  },
  methods: {
    embedPdf(file, pageNumber) {
      if (!file) {
        return;
      }
      let options = { pdfOpenParams: { view: 'FitV' }, page: pageNumber};
      PDFObject.embed(file, `#${this.setting}-embeded-pdf`, options);
    },
    selectReport(report) {
      this.setCurrentReport(report);
      //this.embedPdf(report.report_file, 1);  //don't need to call this as there's a watch on currentReport that does it
    },
    ...mapActions({
      setCurrentReport: 'project/setCurrentReport'
    })
  },
  watch: {
    $route (to){
      if (to.query.report) {
        let reportToFind = this.reports.find(report => report.id == to.query.report);
        this.selectReport(reportToFind);
      }
    },
    currentInsight(newValue) {
      if(newValue?.reportview_set.length > 0) {
        let report = newValue?.reportview_set[0].report;
        this.setCurrentReport(report);
      }
    },
    viewerMode(newValue) {
      if(newValue == 'Insights' && this.currentInsight?.reportview_set.length > 0) {
        let report = this.currentInsight?.reportview_set[0].report;
        this.setCurrentReport(report);
      } else if (newValue == 'Reports') {
        this.setCurrentReport(this.selectedStudy.reports[0]);
      }
    },
    currentReport(newValue) {
      if (this.isReportsMode) {  //if viewerMode == Insights then the viewerMode watch calls embedPDF with the correct page number already so don't call it again
        this.embedPdf(newValue.report_file, 1);
      } else if (this.isInsightsMode) { //we need this watcher for cases where we don't change viewerMode, just create/edit a reportInsight
        let pageNumber = this.currentInsight?.reportview_set[0].page_number;
        this.embedPdf(newValue.report_file, pageNumber);
      }
    }
  }
};
</script>

<style scoped>
.report-container {
  position: relative;
  margin: 0;
  flex-grow: 1;
  background-color: var(--grey-200);
  height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
}

.pdfobject-container {
  width: 100%;
  height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
}

#embeded-pdf {
  margin: 0;
  width: 100%;
}

.report-selection-container {
  background-color: var(--grey-400);
  width: 17.188rem;
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  height: 2.375rem;
}

.report-selection-title {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  left: 0;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  font-size: 0.925rem;
  color: var(--grey-900);
  font-weight: 660;
}

.dd {
  max-width: 9.375rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-button {
  width: calc(100% - 3rem);
}

.report-dropdown /deep/ .dropdown-menu {
  overflow-y: scroll;
  max-height: 37.5rem;
}

.btn-group {
  margin-top: 0;
}
</style>