<template>
  <div id="graph_panel_super_container">
    <section class="d-flex" id="graph_panel_container">
      <div class="mt-5 ml-4 d-flex justify-content-center"  v-if="dataIsLoading && probePoints.length === 0">
        <b-spinner small variant=primary></b-spinner>
        <span class="ml-2 mt-0">loading data for graph...</span>
      </div>
      <div class="mt-5 font-italic" v-if="!dataIsLoading && probePoints.length===0">
        <div class="clear-button">
          <div id="toggle_probes">
            <div class="d-flex flex-row align-items-center">
              <b-button :disabled="dataProbeStatus" class="py-0 fpp-buttons" size="sm" :variant="toggleProbesButtonVariant" @click="toggleProbes">
                <div class="d-flex flex-row align-items-center">
                  <b-icon :icon="toggleProbesIcon" class="mr-tiny"/>{{ toggleProbesText }}
                </div>
              </b-button>
            </div>
          </div>
        </div>
        <div class="ml-4">
          Select one or more points on the study building to graph
        </div>
      </div>
      <div v-else class="no-top-margin-all-descendants"> <!-- render graphs -->
        <span v-if="dataError" class="text-danger">{{dataError}}</span>
        <div v-else>
          <div v-if="!dataIsLoading || probePoints.length !== 0" >
            <div class="clear-button">
              <div id="toggle_probes" class="d-inline-block">
                <b-button :disabled="dataProbeStatus" class="py-0 fpp-buttons" size="sm" :variant="toggleProbesButtonVariant" @click="toggleProbes">
                  <div class="d-flex flex-row align-items-center">
                    <b-icon :icon="toggleProbesIcon" class="mr-tiny"/>{{ toggleProbesText }}
                  </div>
                </b-button>
              </div>
              <b-button v-if="probePoints.length > 0" class="ml-2 py-0 fpp-buttons" size="sm" variant="outline-secondary" @click="clearProbePoints">
                <div class="d-flex flex-row align-items-center">
                  <b-icon-x-circle class="mr-tiny"/>Clear
                </div>
              </b-button>
            </div>
            <div v-for="probe in probePoints" :key="probe.label" class="mb-2">
              <b-badge pill variant="primary" class="ml-3">{{probe.label}}</b-badge>
              <b-button class="float-right" size=sm variant="link-secondary" @click="removeProbe(probe)"><b-icon-x/></b-button>
              <four-point-plot class="mt-2" v-if="probe.error == null" :data="dataAtProbePoint(probe)" :height="graphHeight" :width="graphWidth" />
              <div class="text-danger" v-else>{{probe.error}}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-tooltip target="toggle_probes" id="toggle_probes_tooltip" v-if="dataProbeStatus">
      graph probes are disabled when data probes are active
    </b-tooltip>
    <b-modal id="confirm-clear-probes" centered hide-header>
      <p>Changing the specification will clear any existing plots. Are you sure?</p>
      <template #modal-footer>
        <b-button @click='$bvModal.hide("confirm-clear-probes")' class='float-left'>Cancel</b-button>
        <b-button @click='changeSpecification' variant='danger' class='float-right'>Clear</b-button>
      </template>
    </b-modal>  
  </div>
</template>

<script>
import axios  from 'axios';
import pako from 'pako';
import FourPointPlot from './FourPointPlot.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'GraphPanel',
  components: {
    FourPointPlot
  },
  data() {
    return {
      dataForSelectedGraph: null,
      dataIsLoading: false,
      dataError: '',
      graphHeight: 250,
      graphWidth: 400,
      graphDetails: new Map(),
      probesActive: false
    };
  },
  props: {
    // expects a list of probe points formatted like:
    // [
    //   {label: '1', point: {x, y, z}},
    //   ...
    // ]
    probePoints: {
      type: Array,
      required: true
    },
    graphAssets: {
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    graphPanelKey: {
      required: true
    }
  },
  beforeDestroy() {
    this.cleanUp();
  },
  computed: {
    graphAsset() {
      if (this.graphAssets.length) {
        return this.graphAssets[0].asset;
      } else {
        return null;
      }
    },
    toggleProbesText() {
      return this.probesActive ? 'Exit' : 'Add';
    },
    toggleProbesButtonVariant() {
      return this.probesActive ? 'outline-secondary' : 'outline-primary';
    },
    toggleProbesIcon() {
      return this.probesActive ? 'x-circle' : 'plus-circle'; 
    },
    ...mapGetters('project/simulationAsset', ['configurationId']),
    ...mapGetters('project/viewer', ['dataProbeStatus'])
  },
  methods: {
    changeSpecification() {
      this.toggleProbes();
      this.cleanUp();
      this.downloadGraphData();
      this.$bvModal.hide('confirm-clear-probes');
    },
    cleanUp() {
      this.$emit('setGraphRequiresProbes', false);
      this.clearProbePoints();
      this.setGraphProbeStatusStore(false);
    },
    toggleProbes() {
      if(!this.probesActive) {
        this.$emit('setGraphRequiresProbes', true);
        this.$emit('probeTypeChanged', 'geometry.study');
        this.setDataProbeStatusStore(false);
        this.setGraphProbeStatusStore(true);
      } else {
        this.$emit('setGraphRequiresProbes', false);
        this.$emit('probeTypeChanged', null);
        this.setGraphProbeStatusStore(false);
      }

      this.probesActive = !this.probesActive;
    },
    clearProbePoints() {
      this.$emit('clearProbePoints');
      this.$forceUpdate();
    },
    removeProbe(probe) {
      this.$emit('removeProbe', probe);
      this.$forceUpdate();
    },
    dataAtProbePoint(probePoint) {
      if(this.dataForSelectedGraph?.mesh_points?.length > 0) {
        // find closest point to clicked point in points array then get the point data for that point
        let px, py, pz, distance; // point x,y,z + temp distance
        let pIdx = 0; // point index
        let dIdx; // data index
        let minDistance = Number.MAX_SAFE_INTEGER; // minimum distance
        for(let point of this.dataForSelectedGraph.mesh_points) {

          //coordinates from the viewer are y-up, vtp and json files from post processor are z-up so we need to transform the coordinates before probing into the data
          px = -point[0]; //x becomes -x
          py = point[2];  //z becomes y
          pz = point[1];  //y becomes z

          distance = Math.sqrt((px - probePoint.point[0])**2 + (py - probePoint.point[1])**2 + (pz - probePoint.point[2])**2);
          if(distance < minDistance) {
            minDistance = distance;
            dIdx = pIdx;
          }

          pIdx++;
        }

        // only return data points that are less than 1.5m away from clicked point
        if(minDistance < 1.5) {
          return this.getFourPointPlotDataAtSelectedLocation(dIdx);
        } else {
          probePoint.error = `No data found within 1.5m of the probed location.  Nearest match is ${Math.round(minDistance * 100)/100}m away`;
          this.$forceUpdate();
        }
      }

      return null;
    },
    getFourPointPlotDataAtSelectedLocation(dataIndex) {
      let min_obj = { name: 'CpMin', data: [] };
      let max_obj = { name: 'CpMax', data: [] };
      let mean_obj = { name: 'CpMean', data: [] };
      let rms_obj = { name: 'CpRMS', data: [] };
      for (let key of Object.keys(this.dataForSelectedGraph)) {
        if (key != 'mesh_points') {
          let wd = this.dataForSelectedGraph[key];
          min_obj['data'].push({
            x: parseInt(key),
            y: wd['CpMin_pred'][dataIndex]
          });
          max_obj['data'].push({
            x: parseInt(key),
            y: wd['CpMax_pred'][dataIndex]
          });
          mean_obj['data'].push({
            x: parseInt(key),
            y: wd['CpMean_pred'][dataIndex]
          });
          rms_obj['data'].push({
            x: parseInt(key),
            y: wd['CpRMS_pred'][dataIndex]
          });
        }
      }

      min_obj['data'] = min_obj['data'].sort((a, b) => a.x < b.x ? -1 : 1);
      max_obj['data'] = max_obj['data'].sort((a, b) => a.x < b.x ? -1 : 1);
      mean_obj['data'] = mean_obj['data'].sort((a, b) => a.x < b.x ? -1 : 1);
      rms_obj['data'] = rms_obj['data'].sort((a, b) => a.x < b.x ? -1 : 1);

      let data_for_graph = [min_obj, max_obj, mean_obj, rms_obj];
      return data_for_graph;

    },
    async downloadGraphData() {
      this.dataIsLoading = true;
      this.dataError = '';

      let config = {
        responseType: 'arraybuffer',
      };

      const instance = axios.create(config);
      const fileExtension = this.getFileExtension(this.graphAsset.simulationResult.asset_file);

      try {
        const response = await instance.get(this.graphAsset.simulationResult.asset_file, config);
        const rawData = new Uint8Array(response.data);

        // Decompress the data
        if (fileExtension == 'gz') {
          const decompressedData = pako.inflate(rawData, { to: 'string' });
          this.dataForSelectedGraph = JSON.parse(decompressedData);
        } else {
          this.dataForSelectedGraph = JSON.parse(new TextDecoder().decode(rawData));
        }
        this.dataIsLoading = false;
      } catch (error) {
        this.dataError = 'Error occurred during data retrieval.';
        this.dataIsLoading = false;
      }
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      const lastPart = parts[parts.length - 1].toLowerCase();
      const extension = lastPart.split('?')[0];
      return extension;
    },
    ...mapActions({
      setGraphProbeStatusStore: 'project/viewer/setGraphProbeStatus',
      setDataProbeStatusStore: 'project/viewer/setDataProbeStatus'
    })
  },
  watch: {
    configurationId() {
      this.clearProbePoints();
    },
    isVisible(newValue) {
      if(!newValue && this.probesActive) {
        this.toggleProbes();
      }
    },
    graphPanelKey() {
      if(this.probePoints.length) {
        this.$bvModal.show('confirm-clear-probes');
      } else if(this.probesActive) {
        this.toggleProbes();
        this.cleanUp();
        this.downloadGraphData();
      } else {
        this.cleanUp();
        this.downloadGraphData();
      }
    }
  }
};
</script>

<style scoped>
#graph_panel_super_container {
  padding-top: 15px;
}

#graph_panel_container {
  height: calc(100vh - 320px);
  overflow-y: auto;
  z-index: 20;
}

.fpp-buttons {
  border: none;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
}

.clear-button {
  position: absolute;
  top: 150px;
  margin-left: 15px;
  z-index: 20;
}

#toggle_probes_tooltip {
  margin-top: -40px;
}

.mr-tiny {
  margin-right: 0.15rem;
}
</style>